.Enable_masking {
  height: 28px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  color: #393738;
   margin-bottom:5px;
}

.payment_message {
  margin: 14px 0 30px 0;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  color: #393738;
}
.payment_message .text-style-1 {
  font-weight: bold;
  color: #1a4ef8;
}

.payment_input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding: 0;
  margin-bottom:31px;
}

.amount_text {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 600;
  color: #000;
}

.amount {
  margin-top:8px;
  padding: 10px 0 11px 14px;
  border-radius: 8px;
  border: solid 1px rgba(127, 163, 189, 0.25);
  background: white;
}

.Rs {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  color: #393738;
}

.payment_options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding: 0;
}

.upi_section {
  margin-top: 8px;
  padding: 14px 0;
  border-radius: 8px;
  border: solid 1px rgba(127, 163, 189, 0.25);
  background-color: #fff;
}

.upi_options {
  display: flex;
  flex-wrap: wrap;
}

.pay {
  height: 63px;
  margin: 0 17.7px 13.5px 19px;
}

.pay > img {
  width: 40px;
  height: 40px;
  margin: 0 6px 8px 5px;
  object-fit: contain;
}

.pay > p {
  height: 15px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  line-height: 2.4;
  color: rgba(0, 0, 0, 0.85);
}

.scan_QR {
  align-items: center;
  display: flex;
  margin: 11.5px 14px 0;
  padding: 10px 16px 10px 14px;
  border-radius: 8px;
  background-color: #ffc50e;
  cursor: pointer;
}

.scan_QR > img:nth-child(1) {
  width: 24px;
  height: 24px;
  margin-right:9.4px;
  object-fit: contain;
}

.scan_QR > p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  color: rgba(0, 0, 0, 0.85);
}

.next_img {
  margin-left:21.6px;
}

.qr_code {
  border-radius: 10px;
  background: white;
  top: 24%;
  position: absolute;
  width: 320px;
  text-align: center;
}

.qrCode_img {
  width: 206px !important;
  height: 206px !important;
  margin: 31px 0 0 0;
}

.confirm{
  width: 100%;
  height: 23px;
  margin: 17px 0 14px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #233332;
}

.gm {
  margin: auto;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: -0.12px;
  text-align: center;
  color: #233332;
}

.close_btn {
  border: none;
  width: 61px;
  margin-bottom: 10px;
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 0.36;
  text-align: center;
  color: #959595;
  background: white;
}
