@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assests/fonts/Helvetica.otf') format('opentype');
}

@font-face {
  font-family: 'BigNoodleTitling';
  src: url('./assests/fonts/big_noodle_titling.ttf') format('opentype');
}



*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button{
  cursor:pointer;
}

.main_container{
  height:100%;
}



.header {
  width:100%;
  height: 70px;
  padding: 15px 20px 19px 20px;
  background-color: #ffc50e;
}

.header_inner {
  justify-content: space-between;
  height: 27px;
  margin-top: 7px;
  display: flex;
  align-items: center;
}



.scan_connect {
  margin-bottom: 4px;
  height: 31px;
  -webkit-text-stroke: 1px #000;
  font-family: BigNoodleTitling;
  font-size: 29.1px;
  letter-spacing: 1.45px;
  color: #1a1818;
}
.scan_connect .text-style-1 {
  letter-spacing: 1.43px;
}
.scan_connect .text-style-2 {
  letter-spacing: normal;
}


.user_icon >svg {
  width: 25px;
  height: 25px;
}

.log_btn{
  padding:7px 2px;
  border:1px solid black;
    background: #ffc50e;
    border-radius:8px;
  top: 58px;
  position: absolute;
  transform: translateX(355px);
  display: none;
  transition: all 1s ease-in-out;
}



.log_btn>a{
  text-decoration:none;
  color: black;
  align-items: center;
  display: flex;
  gap:3px;
}



/*-------------------------------------------------------------- Header2 ----------------------------------------------------------------*/
.scan_connect_2 {
  width: 326px;
  height: 36px;
  -webkit-text-stroke: 1px #000;
  font-family: BigNoodleTitling;
  font-size: 29.1px;
  letter-spacing: 1.45px;
  color: #1a1818;
}

.scan_connect_2 .text-style-1 {
  letter-spacing: 1.43px;
}
.scan_connect_2 .text-style-2 {
  letter-spacing: normal;
}

.header_inner > div{
  cursor: pointer;
}

.header_inner > .back_icon{
  width: 36px;
  height: 36px;
  padding: 9.1px 13.9px 9.9px 0;
}

.header_inner > div > a> img {
  margin: 4px 0 4px 0px;
  cursor: pointer;
}


/*------------------------------------------ maindiv----------------------------------------------------- */

.main_div{
  margin: 20px auto;
  padding-left: 20px;
  padding-right: 20px;
}

.vehicledata_input {
  position: relative;
}

.vehicledata_input label {
  position: absolute;
  top:16px;
  left:21px;
  transition: all 0.2s ease-out;
  pointer-events: none;
  height: 20px;
  opacity: 0.4;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  color: #273850;
}


.vehicledata_input.active label {
  top: 5px;
  left: 8px;
  font-size: 10px;
  color: #273850;
}


/* Error Page */
.error_page{
    text-align: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
    margin-top: 300px;
    border-radius:30px;
    border: 1px solid grey;
    box-shadow: 10px 10px 10px grey;
}


/* Loading-Page */
.loading{
  display: flex;
    height:600px;
    width: 100%;
    align-items: center;
}


.loading-top {
  border-radius: 50%;
  margin:30px auto;
  width: 50px;
  height:50px;
  border: 5px solid white;
  border-top: 5px solid black;
  border-bottom:5px solid black;
  animation: rotateLoading 0.7s linear infinite;
}

@keyframes rotateLoading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* INPUT FIELD FOCUSED CSS */
.focused{
  border: 2px solid red;
}


/* Error Page */
#notfound {
  background: #f8f8ff;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.notfound-404 h3 {
  font-size: 3em;
}

.error_code {
  font-size: 6rem;
}

.notfound h2 {
  font-size: 2em;
  height: 100px;
}



/* Message Box */
.message_box{
  text-align: center;
}

.message_box > svg{ 
  outline: 1px solid grey;
  outline-offset: 5px;
  font-size: 70px;
  box-shadow: 2px 2px 2px grey;
}

.message_box ~ h4{
  font-weight: 300;
}