.contact{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    color: #393738;
  }
  
  .contact_line {
    height: 3px;
    margin-top: 5px;
    border-radius: 1.5px;
    background-color: rgba(127, 163, 189, 0.25);
}  


  
  .car_id {
    margin:8px 40px 5px 0;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    color: #393738;
  }
  
  .para_1{
    margin-bottom: 12px;
    font-family:HelveticaNeue;
    font-size: 12px;
    color: #707070;
  }
  
  .contact_options{
    margin-bottom: 12px;
    padding: 11px 18px 11px 21px;
    border-radius: 8px;
    border: solid 1px rgba(127, 163, 189, 0.25);
    background-color: #fff;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
  
  .checkbox_1{
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      color: #273850;
  }
  
  .Ellipse_5 {
    width: 17px;
    height: 17px;
    border: solid 1px rgba(127, 163, 189, 0.25);
    background-color: #fff;
    cursor: pointer;
  }
  
  .btn_section{
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  .btn_section> .btn:nth-child(1) {
    width: 50%;
    height: 44px;
    border-radius: 8px;
    background-color: #003a8e;
    border: none;
}
  
.btn_section> .btn:nth-child(2) {
  width: 50%;
  height: 44px;
  border-radius: 8px;
  background-color: #44a735;
  border: none;
}
  
  .btn>span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: #fff;
  }
  
  .emergency_message {
    margin-top:16px;
    padding: 9px 10px 14px;
    border-radius: 11px;
    border: solid 1px #f00;
    background-color: #fff;
  }
  
  .Emergency_Connect{
      margin: 2px 0 18px3px;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 600;
      color: #273850;
      display: flex;
      justify-content: space-between;
    
  }
  
  .Image-1 {
    width: 22px;
    height: 22px;
    margin: 0 2px 10px 276px;
    object-fit: contain;
  }
  
  .para_2{
    margin-top: 10px;
    font-family:HelveticaNeue;
    font-size: 12px;
    color: #707070;
  }
  
  .message_btn{
    background-color: white;
    width: 100%;
    margin-top: 15px;
    border-radius: 8px;
    border: solid 1px #e71f18;
    height: 44px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    color: #e71f18;
  }
  


  /* Call section */
.call_section>input{
  height: 35px;
    width: 100%;
    margin:10px auto;
    border-radius:8px;

}


.call_main>div {
  background:white;
  padding: 20px 15px;
  border-radius: 20px;
}


.call_btn{
  border-radius: 8px;
  height:50px;
  width: 100%;
  text-align: center;
  background: black;
 color:#ffc50e;
 font-size: 16px;
 text-transform: uppercase;
  }

  .modal{
    top:30%;
    position: absolute;
    display: flex;
    width: 100%;
}

 
  .call_main{
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
     gap: 20px;
     padding: 0 20px;
  }


  .call_main>div>p {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 800;
}

.cancel_btn{
  font-size: 15px;
  border: none;
  background-color: white;
}