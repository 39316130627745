
.login_mobile>p:nth-child(1){
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    color: #393738;
  }

  .login_mobile>div{
    font-family: Poppins;
    font-size: 13px;
    font-weight: 300;
    color: #393738;
}

.login_mobile>.para_4{
  margin: 20px 0 10px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 300;
    color: #393738;
  
}

.num_input{
        width: 320px;
        height: 50px;
        margin-top:20px;
        padding: 15px 73px 15px 21px;
        border-radius: 8px;
        border: solid 1px rgba(127, 163, 189, 0.25);
        background-color: #fff;
      }

.num_input::placeholder{
    width: 226px;
    height: 20px;
    opacity: 0.4;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #273850;
  }