.banner {
  width: 100%;
  background: url("../assests/banner4.png");
  color: white;
  padding: 50px;
  background-size: cover;
  background-position: center;
  height: 500px;
  display: flex;
}

p {
  font-family: Poppins;
}

h2{
  font-family:BigNoodleTitling;
  letter-spacing: 1px;
}


.banner_inside{
    display: flex;
    justify-content:space-evenly;
    flex-wrap: wrap;
    align-self: center;
}

.banner_inside>div{
  align-self: center;
}

.banner_inside div:nth-child(1){
  margin-top:30px;
}

.banner_inside div:nth-child(1)>h1{
    font-size:60px;
    font-weight: 800;
    font-family:HelveticaNeue;
}

.banner_inside div:nth-child(1)>p{
     margin-top:50px;
     display: flex;
     align-items: center;
     font-family:HelveticaNeue;
  }

.banner_inside div:nth-child(1)>p>img{
  width: 80px;
  height: 50px;
  cursor: pointer;
  transition:all 0.5s ease-in-out;
}

.banner_inside div:nth-child(1)>p>img:hover{
    transform:translateY(-10px);
  }

.banner_inside div:nth-child(1)>p>span{
   font-size: 30px;
  }

  .banner_inside div:nth-child(2)>img{
    transform: rotate3d(1, 1, 1, 27deg);
}


.banner div:nth-child(2){
    text-align:left;
}

.banner div:nth-child(2)>p{
font-size: 40px;
font-weight: 800;
color:#ffc50e;
 transform: translateY(-12px) rotate(-11deg);
 }


 /* banner_box */

 .banner_box {
  display: flex;
  justify-content: center;
  padding: 0px 15px;
 flex-wrap: wrap; 
}


.banner_box>div{
  padding: 20px 0;
}



.banner_box>div:nth-child(1) {
  text-align: center;
  align-self: center;
  width: 50%;
}

.banner_box div:nth-child(2) {
  width: 40%;
}

 .banner_box div:nth-child(2)>h3{
  font-family:BigNoodleTitling;
    text-transform:uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    font-size:25px;
    margin-bottom: 20px;
 }

 .banner_box div:nth-child(1)>img {
  width: 450px;
  height: 210px;
  box-shadow: 5px 5px 5px grey;
}

 .banner_box div:nth-child(2)>p{
  line-height:27px;
}

 .banner_box div:nth-child(2)>h3>span{
   color: #ffc50e;
 }

 .banner_box div:nth-child(2)>p>span{
    font-weight:600;
  }

 .sticker_price{
  letter-spacing: 1px;
  font-family:BigNoodleTitling;
  background:black;
  color: white;
  font-size:25px;
  text-align: center;
  padding: 10px 5px;
  text-transform: uppercase;
  border-radius: 10px;
  transition:all 0.5s ease-in-out;
  cursor: pointer;
  margin: 10px auto;
}

.sticker_price svg:nth-child(1){
  background-color: red;
  border-radius: 50%;
  color: white;
}


.sticker_price svg:nth-child(2){
  color: white;
}

.banner_icons{
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}



 @media only screen and (max-width:760px) {
  .banner_box div:nth-child(1){
    width:100%;
}

.banner_box div:nth-child(2){
 width: 100%;
}

 }

 /* quote */

 .quote{
    background: white;
    padding: 20px 0;
    text-align: center;
 }

 .quote>p{
  max-width: 700px;
    margin: auto;
    border-radius:10px;
    background:#ffc50e;
    padding: 20px 15px;
    font-size:20px;
    font-weight: 600;
    cursor: pointer;
    transition:all 0.5s ease-in-out;
}

.quote>p:hover{
    transform: scale(1.1);
}



/* banner_category */


.banner_category{
  padding: 70px 95px;
  background: url("../assests/banner3.png");
  background-size: cover;
  background-position: center;
  height: 500px;
}

.banner_category{
  display: flex;
  justify-content: space-around;
  align-items: center;
} 

.boy-text-node {
  color: white;
    border-radius: 20px;
}

.boy_img{
  height: 100%;
  transform: scale(1.1);
  max-height: 414px;
}


.banner_category>div>p{
  align-self: center;
  padding:20px;
  font-weight: 800;
  font-size: 30px;
  margin-bottom: 20px;
}



/* youtube section */
.youtube_section{
  padding: 20px 200px;
  height: 600px;
  color: white;
  position: relative;
  text-align: center;
    background-image: url('../assests/banner2.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}





.youtube_section>p{
  padding-top: 50px;
  text-align:center;
  font-size: 100px;
  font-weight: 800;
  line-height: 110px;
}

.youtube_section>p>span{
  color: #ffc50e;
}

.youtube_section h2{
  box-shadow: 2px 2px 2px white;
  letter-spacing: 2px;
  margin-top: 100px;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid white;
}


/* feature Section */

.feature_section{
  background-color:#CBECEA;
  color: #000;
  text-align: center;
  padding: 50px 0;
  margin-top: 40px;
}

.feature_box {
  display: flex;
  flex-wrap: wrap;
  gap:25px;
  margin: 30px 30px 0 30px;
  justify-content: center;
}

.feature_box p{
  text-align: left;
  height: 100px;
  gap: 25px;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid rgb(181, 179, 179);
  font-weight:400;
  font-size: 20px;
  padding: 0 20px;
  box-shadow: 3px 3px 8px grey;
  width:30%;
  border-radius: 10px;
  transition:all 0.5s ease-in-out;
  cursor:pointer;
}

.feature_box p svg{
  width: 50px;
  height:50px;
  padding:5px;
  border-radius: 50%;
  box-shadow: 2px 2px 2px grey;

}

.feature_box p:hover{
    transform:translateY(-10px);
  }


  .feature_section>h2{
    margin-bottom:20px;
     font-size:60px;
  }


/* Category Section */

.category_section{
  background-color:rgb(226, 226, 250);
  padding: 30px;
}


.category_section>h2{
  padding: 20px 0;
font-size: 43px;
font-weight: 600;
Color:#000;
}

.category_section>div{
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.category_section div img{
  max-width: 500px;
  cursor: pointer;
  transition:all 0.5s ease-in-out;
}

.category_section div img:hover{
    transform:translateY(-10px);
  }



  .category_section div img:nth-child(2){
    border-radius: 17px;
  }


/*  About Us */
  .about_us{
    background-color: #CBECEA;
    padding: 40px 0px;
    text-align: center;
  }

 .about_us>h2{
  color: #E33C3C;
  text-align: right;
  margin-right: 50px;
 }

  .about_us_content{
    gap: 50px;
    justify-content:center;
       display: flex;
       flex-wrap: wrap;
  } 


  .about_us_content>h2{
    font-size: 50px;
    width: 50%;
  }

  .about_us_content>h2>p {
    font-size: 20px;
    margin-top: 20px;
    color: #3a3a3a;
}

  /* Footer */
  .footer{
    background: white;
  }

.footer_links{
    padding: 30px 50px;
    display: flex;
    justify-content: space-around;
  }

  .footer_links>div>h2{
    color: #1B1A39;
    font-size: 27px;
      font-weight: 800;
  }

  
  .copyright_section{
    text-align: center;
    background:rgb(13, 13, 97);
    color: white;
    padding:10px;
  }

  .copyright_section>p{
    font-size: 12px;
    margin-bottom: 0;
  }

  .copyright_section>p>span{
    color: #ffc50e;
    font-family:BigNoodleTitling;
    letter-spacing: 1px;
  }

  .footer_options{
      line-height: 27px;
      margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .footer_options>p{
    margin-top: 10px;
    font-size: 18px;
    color: #1B1A39;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .footer_options>p>span{
    font-family:Poppins;;
  }



  .footer_options>p>svg{
    color: #BF2F52;
  }


  .share-option>span {
    color: white;
    height: 50px;
    width: 50px;
    padding: 10px 5px 0 10px;
    border-radius: 50%;
    cursor: pointer;
}

   .share-option>span:nth-child(1){
    background: blue;
   }

   .share-option>span:nth-child(1):hover{
    background: rgb(94, 94, 242);
   }

   .share-option>span:nth-child(2){
    background: black;
   }

   .share-option>span:nth-child(2):hover{
    background: rgb(60, 60, 60);
   }

   .share-option>span:nth-child(3){
    background: red;
   }

   
   .share-option>span:nth-child(3):hover{
    background: rgb(243, 86, 86);
   }



  .fb{
    color: blue;
    background-color: white;
    border-radius: 50%;
    font-size: 30px;
  }

  .insta,.youtube{
    font-size: 30px;
  }
  


/* Media Queries */

@media only screen and (max-width:1200px) {
  .banner_category>div{
    gap: 50px;
  }

  .quote>p {
    font-size: 22px;
  }

  .feature_box {
    margin:30px 10px 0 50px;
  }


    .banner_box div:nth-child(1) {
         width: 100%;
    }

    
    .banner_box div:nth-child(2) {
      width: 80%;
 }
 
 .feature_box p {
  gap:15px;
  height: 60px;
  width: 32%;
  font-size: 14px;
}

.feature_box p svg {
  width:30px;
  height:30px;
}
    
  
}

@media only screen and (max-width:992px) {
  .banner_inside div:nth-child(1)>h1 {
    font-size:80px;  
}

.youtube_section{
  padding: 0;
}
}



@media only screen and (max-width:768px) {
  .banner_category{
   flex-wrap: wrap;
   flex-direction: column-reverse;
  }

  .feature_box p {
    width:50%;
  }

  .banner_inside div:nth-child(1)>h1 {
    font-size:40px;
}

.banner div:nth-child(2)>p {
  margin-top: 30px;
  font-size: 20px;
}

.banner_box {
    padding: 0 35px;
}

.banner_box div:nth-child(2)>h3 {
  font-size: 20px;
}


.boy_img {
    transform: scale(1.2);
    width: 300px;
    height: 200px;
}


.banner_category>div>p{
  margin-top: 20px;
  padding:0 20px;
  font-size:20px;
}


.youtube_section>p {
  margin-top: 100px;
  font-size: 30px;
}

.about_us>h1 {
  margin-right:0;
  margin-bottom: 10px;
}

.about_us_content>h1 {
  font-size:20px;
  width:100%;
}

  .feature_box {
    margin:30px 10px 0 10px;
  }

.footer_links {
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
}

.category_section>h1 {
  font-size:20px;

}

.category_section div img{
  max-width:250px;
}

.quote>p {
  max-width: 350px;
  font-size: 11px;
  padding:5px 10px;
}

.youtube_section>p {
  line-height:36px;
}

.sticker_price{
  font-size:15px;
}
}


@media only screen and (max-width:600px){
  .banner_box div:nth-child(1)>img {
    width: 200px;
    height: 100px;
  }

  .feature_box p {
    width:70%;
  }

  .banner_category{
    flex-wrap:nowrap;
   }
  
}


@media only screen and (max-width:400px) {
  .banner_box div:nth-child(2) {
    width: 100%;
}

  .banner_inside div:nth-child(1)>h1 {
    font-size: 30px;
}

.banner_category{
  flex-wrap:wrap;
 }


.banner div:nth-child(2) {
  margin-top: 30px;
}

.banner_box div:nth-child(1)>img {
  width: 200px;
  height: 93px;
}

.banner_box div:nth-child(2)>h3 {
  font-size:15px;
  margin-bottom: 10px;
}

.banner_box div:nth-child(2)>p {
  line-height: 18px;
  font-size: 10px;
}



.banner div:nth-child(2)>p {
  transform: translateY(-32px) rotate(-11deg);
}

  .banner_inside{
    margin-top: 0px;
  }

  .quote {
    padding: 20px 30px;
}

  .quote>p {
      padding: 2px 5px;
      font-size: 10px;
}

.banner_category {
  padding: 30px 20px;
}

.banner_category>div:nth-child(2) {
  height: 20%;
}


.sticker_price{
  font-size:10px;
}

.feature_section>h2 {
  font-size:30px;
}

.feature_box{
  margin: 0;
}

.feature_box>p{
  width:100%;
  margin: 0 10px;
  font-size: 10px;
}

.footer_links {
  padding: 30px 20px;
}

.footer_options {
  line-height: 20px;
}

.footer_links>div>h3 {
  font-size: 20px;

}

.boy_img {
    width: 190px;
}

.banner_category>div>p {
  font-size: 15px;

}

.about_us+div>p{
  margin: 0px 20px !important;
}

.about_us_content>h2 {
  font-size:30px;
  width:100%;
}

.about_us_content>h2>p {
  font-size: 15px;
}

.footer_options>p {
  font-size: 13px;
}

.youtube_section {
  padding: 20px 30px;
}

.youtube_section h2{
  margin-top:50px;
}

.category_section>h2 {
  font-size:30px;
}
}