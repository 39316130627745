
.verify_mobile>p:nth-child(1){
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  color: #393738;
}

.verify_mobile>div{
        font-family: Poppins;
        font-size: 13px;
        font-weight: 300;
        color: #393738;
}

.verify_mobile>p:nth-child(3){
  margin:20px 0;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 300;
  color: #393738;
}

.verify_mobile>p:nth-child(3)>span{
    font-weight: 600;
}

.input_otp{    
  font-size: 15px;
  padding-left: 12px;
        width: 160px;
        height: 50px;
        display: block;
        margin: auto;
        padding-left: 12px;
        width: 160px;
        border-radius: 8px;
        border:solid 2px rgb(32 139 217 / 25%);
        background-color: #fff;
}

.input_otp:focus{
  border: 2px solid #006BD6;
}

.input_otp:focus-visible {
 outline: none;
}

.otp_btn {
  border: 1px solid grey;
  border-radius:5px;
  background: #0000;
  color: #000;
  cursor: pointer;
  font-family: Poppins;
  font-size: 10px;
  margin:10px auto;
  width: 70px;
  display: block;
  font-weight: 500;
  opacity: 1;
}

  .submit_btn{
    width:100%;
    height: 44px;
    border-radius: 8px;
    background-color: #ffc50e;
    border: none;
    color: black;
  }

  .submit_btn>.submit{
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        color: #000;
      
  }


  /* Otp Success */
  .otp_success {
    text-align: center;
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    font-family: Poppins;
}

