.edit_profile{
        margin-bottom: 30px;
}

.Edit-Profile {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    color: #393738;
  }

  .input_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
   }

   .input_box>span{
    opacity: 0.3;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    color: #000;
    margin-bottom: 15px;
  }


.input_box_inner{
  position: relative;
}

.input_box_inner >input{
  height: 50px;
  padding: 10px 40px 0px 10px;
  border-radius: 8px;
  border:solid 2px rgb(32 139 217 / 25%);
  background-color: #fff;
  width: 100%;
}

.input_box_inner >input:focus{
   border: 2px solid #006BD6;
}

input:focus-visible {
  outline: none;
}


  .input_box_inner label{
    position: absolute;
    top: 30%;
    left: 10px;
    opacity: 0.7;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 500;
    text-transform: uppercase;
    color: #273850;
    transition: top 0.3s, font-size 0.3s, color 0.3s;
  }
  
  
.active{
    top:5% !important;
    font-size: 12px !important;
  }



  .update_btn{
    margin-top: 20px;
    width: 100%;
    border: none;
    padding: 12px 120px;
    border-radius: 8px;
    background-color: #ffc50e;
  }


.Update {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

