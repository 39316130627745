.Code-Settings {
    margin: 0 0 5px 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    color: #393738;
  }

  .code_line {
    height: 3px;
    margin: 5px 0 0;
    border-radius: 1.5px;
    background-color:rgba(127, 163, 189, 0.25);
}


  .profile_data{
    margin: 20px 0;
    padding: 12px 13px 17px 14px;
    border-radius: 8px;
    border: solid 1px rgba(127, 163, 189, 0.25);
    background-color: #fff;
  }

  .profile_data>span:nth-child(1){
    margin: 0 2px 0 0;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 300;
    color: #393738;
  }

  .profile_data>span:nth-child(1)  .text-style-1 {
    font-weight: 600;
  }

  .profile_data>span:nth-child(2){
    margin: 19px 0 13px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 300;
    color: #393738;
  }

  .line_break{
        height: 1px;
        margin: 13px 4.5px 13px 0.5px;
        background-color: #dfe8ee;
  }

.edit{
  text-align: right;
}

  .edit>a{
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    color: #1a4ef8;
    text-decoration: none;
    cursor: pointer;
  }

  .status_box{
        max-height:368px;
        padding:12px 9px 18px 14.5px;
        border-radius: 8px;
        border: solid 1px rgba(127, 163, 189, 0.25);
        background-color: #fff;   
  }

  .status_top{
        margin-bottom:17px;
        display: flex;
  }

  .Status {
    width: 43px;
    height: 20px;
    margin: 6px 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    color: #273850;
  }

  .status_btn{
    height: 33px;
    margin: 0 0 0 38px;
    padding: 3px 3px 3px 10px;
    border-radius: 16.5px;
    border: solid 1px rgba(127, 163, 189, 0.25);
    background-color: #838683;
    align-items: center;
    display: flex;
  }

  .InActive {
    width: 56px;
    height: 20px;
    margin: 3px 3px 4px 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
  
  .inactive_btn{
    width: 28px;
    height: 27px;
    margin: 0 0 0 3px;
    border-radius: 13.5px;
    background-color: #fff;
  }

  .status_btn_1{
        height: 33px;
        margin: 0 0 0 38px;
        padding: 3px 17px 3px 3px;
        border-radius: 16.5px;
        border: solid 1px rgba(127, 163, 189, 0.25);
        background-color: #44a735;
        align-items: center;
    display: flex;
  }

 
  .Active {
    width: 44px;
    height: 20px;
    margin: 3px 0 4px 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }

  .active_btn{
        width: 28px;
        height: 27px;
        margin: 0 8px 0 0;
        border-radius: 13.5px;
        background-color: #fff;
  }

  .mask_details{
    margin: 19px 0 17px 3.5px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 300;
    color: #393738;
  }

  .mask_details .text-style-1 {
    font-weight: 600;
  }


  .mask_para{
    margin: 6px 9px 12px 2.5px;
    font-family: Poppins;
    font-size: 10px;
    color: #f00;
  }


  .enable_btn{
        padding: 12px 30px;
        border-radius: 8px;
        background-color: #ffc50e;
        border: none;
  }

  .enable {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    text-decoration: none;
  }

  .sms_details{
    margin: 19px 0 17px 3.5px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 300;
    color: #393738;
  }

  .sms_details .text-style-1 {
    font-weight: 600;
  }
  
  .email_support{
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    color: #393738;
  }
  .email_support .text-style-1 {
    font-weight: 300;
  }