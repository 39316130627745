.activate_code {
    margin-bottom: 24px;
  }
  

.activate_code>p:nth-child(1){
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    color: #393738;
  }

  .activate_code>div{
      height:3px;
      border-radius: 1.5px;
      background-color:rgba(127, 163, 189, 0.25);
  
}

  .activate_code>p:nth-child(3)  {
    margin:17px 0 0 2px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 300;
    color: #393738;
  }


.emergency_input{
    height: 50px;
    margin: 0 0 8px 0px;
    padding: 15px 21px 15px 21px;
    border-radius: 8px;
    border: solid 1px rgba(127, 163, 189, 0.25);
    background-color: #fff;
}


.vehicle_input::placeholder, .emergency_input::placeholder{
  height: 20px;
  opacity: 0.4;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  color: #273850;
} 

.span_field{
    opacity: 0.7;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
   margin-bottom: 15px;
}

  .submit_btn{
    background: transparent;
    border: none;
    font-family: Poppins;
    font-size:14px;
    font-weight: 600;
 }

 .submit_btn>a{
    text-decoration: none;
    color: #000;
 }
